import React from "react";
import "twin.macro";
import { graphql } from "gatsby";
import { PageLayout, PracticeStats, Testimonials, CTA } from "../components";

const About = ({ data: { strapiDsrAbout, allStrapiTestimonials } }) => {
  const { practiceStats, ...rest } = strapiDsrAbout;
  return (
    <PageLayout {...rest}>
      {!!practiceStats?.years && <PracticeStats {...practiceStats} />}
      {/* <OwnerBios /> */}
      {/* <Testimonials
        testimonials={allStrapiTestimonials.edges.map((a) => a.node)}
      /> */}
      <CTA />
    </PageLayout>
  );
};

export default About;

export const query = graphql`
  query aboutPageQuery {
    allStrapiTestimonials(filter: { group: { name: { eq: "DSR" } } }) {
      edges {
        node {
          branch {
            name
          }
          patientName
          text
        }
      }
    }
    strapiDsrAbout {
      content
      practiceStats {
        exams
        header
        patients
        years
      }
      subtitle
      title
      intro
    }
  }
`;
